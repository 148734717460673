import { default as _91_46_46_46all_93NtaOm4TfyLMeta } from "/var/deploy-agents/tgi/_work/1/s/pages/[...all].vue?macro=true";
import { default as indexl7bljnCzSrMeta } from "/var/deploy-agents/tgi/_work/1/s/pages/auth/enroll/index.vue?macro=true";
import { default as _91token_93ku7Ik6QFWfMeta } from "/var/deploy-agents/tgi/_work/1/s/pages/auth/forgot/[token].vue?macro=true";
import { default as indexTzaxVbHcgOMeta } from "/var/deploy-agents/tgi/_work/1/s/pages/auth/forgot/index.vue?macro=true";
import { default as loginupK2LTOCvLMeta } from "/var/deploy-agents/tgi/_work/1/s/pages/auth/login.vue?macro=true";
import { default as logoutwOfcBRKzfhMeta } from "/var/deploy-agents/tgi/_work/1/s/pages/auth/logout.vue?macro=true";
import { default as _91token_93jdmnO6dWlTMeta } from "/var/deploy-agents/tgi/_work/1/s/pages/auth/onboard/[token].vue?macro=true";
import { default as claims_45newdVepx6dwKjMeta } from "/var/deploy-agents/tgi/_work/1/s/pages/client/claims-new.vue?macro=true";
import { default as claims_45viewFz86N3yGWyMeta } from "/var/deploy-agents/tgi/_work/1/s/pages/client/claims-view.vue?macro=true";
import { default as claimsaEqeXfvkNJMeta } from "/var/deploy-agents/tgi/_work/1/s/pages/client/claims.vue?macro=true";
import { default as dashboardcAqIGSewVTMeta } from "/var/deploy-agents/tgi/_work/1/s/pages/client/dashboard.vue?macro=true";
import { default as help47gWholZdGMeta } from "/var/deploy-agents/tgi/_work/1/s/pages/client/help.vue?macro=true";
import { default as indexLcn25i8eeSMeta } from "/var/deploy-agents/tgi/_work/1/s/pages/client/index.vue?macro=true";
import { default as policiesCL5WZQIjytMeta } from "/var/deploy-agents/tgi/_work/1/s/pages/client/policies.vue?macro=true";
import { default as settingsuE5ad0yTQIMeta } from "/var/deploy-agents/tgi/_work/1/s/pages/client/settings.vue?macro=true";
import { default as indexU9EP5GV1Z6Meta } from "/var/deploy-agents/tgi/_work/1/s/pages/index.vue?macro=true";
import { default as claims_45newnVA6o8cuK1Meta } from "~/pages/client/claims-new.vue?macro=true";
import { default as claims_45view2hb6fxRdkOMeta } from "~/pages/client/claims-view.vue?macro=true";
export default [
  {
    name: "all",
    path: "/:all(.*)*",
    component: () => import("/var/deploy-agents/tgi/_work/1/s/pages/[...all].vue").then(m => m.default || m)
  },
  {
    name: "auth-enroll",
    path: "/auth/enroll",
    meta: indexl7bljnCzSrMeta || {},
    component: () => import("/var/deploy-agents/tgi/_work/1/s/pages/auth/enroll/index.vue").then(m => m.default || m)
  },
  {
    name: "auth-forgot-token",
    path: "/auth/forgot/:token()",
    meta: _91token_93ku7Ik6QFWfMeta || {},
    component: () => import("/var/deploy-agents/tgi/_work/1/s/pages/auth/forgot/[token].vue").then(m => m.default || m)
  },
  {
    name: "auth-forgot",
    path: "/auth/forgot",
    meta: indexTzaxVbHcgOMeta || {},
    component: () => import("/var/deploy-agents/tgi/_work/1/s/pages/auth/forgot/index.vue").then(m => m.default || m)
  },
  {
    name: "auth-login",
    path: "/auth/login",
    meta: loginupK2LTOCvLMeta || {},
    component: () => import("/var/deploy-agents/tgi/_work/1/s/pages/auth/login.vue").then(m => m.default || m)
  },
  {
    name: "auth-logout",
    path: "/auth/logout",
    meta: logoutwOfcBRKzfhMeta || {},
    component: () => import("/var/deploy-agents/tgi/_work/1/s/pages/auth/logout.vue").then(m => m.default || m)
  },
  {
    name: "auth-onboard-token",
    path: "/auth/onboard/:token()",
    meta: _91token_93jdmnO6dWlTMeta || {},
    component: () => import("/var/deploy-agents/tgi/_work/1/s/pages/auth/onboard/[token].vue").then(m => m.default || m)
  },
  {
    name: "client-claims-new",
    path: "/client/claims-new",
    meta: claims_45newdVepx6dwKjMeta || {},
    component: () => import("/var/deploy-agents/tgi/_work/1/s/pages/client/claims-new.vue").then(m => m.default || m)
  },
  {
    name: "client-claims-view",
    path: "/client/claims-view",
    meta: claims_45viewFz86N3yGWyMeta || {},
    component: () => import("/var/deploy-agents/tgi/_work/1/s/pages/client/claims-view.vue").then(m => m.default || m)
  },
  {
    name: "client-claims",
    path: "/client/claims",
    meta: claimsaEqeXfvkNJMeta || {},
    component: () => import("/var/deploy-agents/tgi/_work/1/s/pages/client/claims.vue").then(m => m.default || m)
  },
  {
    name: "client-dashboard",
    path: "/client/dashboard",
    meta: dashboardcAqIGSewVTMeta || {},
    component: () => import("/var/deploy-agents/tgi/_work/1/s/pages/client/dashboard.vue").then(m => m.default || m)
  },
  {
    name: "client-help",
    path: "/client/help",
    meta: help47gWholZdGMeta || {},
    component: () => import("/var/deploy-agents/tgi/_work/1/s/pages/client/help.vue").then(m => m.default || m)
  },
  {
    name: "client",
    path: "/client",
    meta: indexLcn25i8eeSMeta || {},
    component: () => import("/var/deploy-agents/tgi/_work/1/s/pages/client/index.vue").then(m => m.default || m)
  },
  {
    name: "client-policies",
    path: "/client/policies",
    meta: policiesCL5WZQIjytMeta || {},
    component: () => import("/var/deploy-agents/tgi/_work/1/s/pages/client/policies.vue").then(m => m.default || m)
  },
  {
    name: "client-settings",
    path: "/client/settings",
    meta: settingsuE5ad0yTQIMeta || {},
    component: () => import("/var/deploy-agents/tgi/_work/1/s/pages/client/settings.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    meta: indexU9EP5GV1Z6Meta || {},
    component: () => import("/var/deploy-agents/tgi/_work/1/s/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "new-claim",
    path: "/client/new-claim",
    meta: claims_45newnVA6o8cuK1Meta || {},
    component: () => import("~/pages/client/claims-new.vue").then(m => m.default || m)
  },
  {
    name: "claim-view",
    path: "/client/claim/:claimId",
    meta: claims_45view2hb6fxRdkOMeta || {},
    component: () => import("~/pages/client/claims-view.vue").then(m => m.default || m)
  }
]