// Auto generated. Use `npm run icons` to regenerate.

// fab
import {
  faCanadianMapleLeaf as fabCanadianMapleLeaf,
  faCcVisa as fabCcVisa,
  faFacebookF as fabFacebookF,
  faLinkedin as fabLinkedin,
  faXTwitter as fabXTwitter,
} from "@fortawesome/free-brands-svg-icons";

// fad
import {
  faBroomWide as fadBroomWide,
  faCircleInfo as fadCircleInfo,
  faFileCirclePlus as fadFileCirclePlus,
  faLoader as fadLoader,
  faTriangleExclamation as fadTriangleExclamation,
} from "@fortawesome/pro-duotone-svg-icons";

// fal
import {
  faArrowDown as falArrowDown,
  faArrowDownLong as falArrowDownLong,
  faArrowRight as falArrowRight,
  faArrowUpFromBracket as falArrowUpFromBracket,
  faArrowsRotate as falArrowsRotate,
  faBriefcase as falBriefcase,
  faChampagneGlasses as falChampagneGlasses,
  faCheck as falCheck,
  faCircleSmall as falCircleSmall,
  faClockRotateLeft as falClockRotateLeft,
  faClone as falClone,
  faComments as falComments,
  faComputer as falComputer,
  faCreditCard as falCreditCard,
  faEnvelopeOpenText as falEnvelopeOpenText,
  faEye as falEye,
  faEyeSlash as falEyeSlash,
  faFileAlt as falFileAlt,
  faFileCirclePlus as falFileCirclePlus,
  faFileLines as falFileLines,
  faFilePdf as falFilePdf,
  faFiles as falFiles,
  faFloppyDisk as falFloppyDisk,
  faGear as falGear,
  faHeartPulse as falHeartPulse,
  faKeyboard as falKeyboard,
  faLanguage as falLanguage,
  faMessageBot as falMessageBot,
  faMoneyFromBracket as falMoneyFromBracket,
  faNfcSlash as falNfcSlash,
  faPeopleRoof as falPeopleRoof,
  faPlus as falPlus,
  faPowerOff as falPowerOff,
  faQuestionCircle as falQuestionCircle,
  faSave as falSave,
  faSearch as falSearch,
  faShieldCheck as falShieldCheck,
  faSquareEllipsisVertical as falSquareEllipsisVertical,
  faTriangleExclamation as falTriangleExclamation,
  faUser as falUser,
} from "@fortawesome/pro-light-svg-icons";

// far
import {
  faArrowLeftLong as farArrowLeftLong,
  faArrowRightToBracket as farArrowRightToBracket,
  faDash as farDash,
  faEnvelopeCircleCheck as farEnvelopeCircleCheck,
  faPrint as farPrint,
  faTrash as farTrash,
} from "@fortawesome/pro-regular-svg-icons";

// fas
import {
  faArrowDown as fasArrowDown,
  faArrowRight as fasArrowRight,
  faBracketsCurly as fasBracketsCurly,
  faCheck as fasCheck,
  faCircleExclamation as fasCircleExclamation,
  faCircleQuestion as fasCircleQuestion,
  faGear as fasGear,
  faGrid2 as fasGrid2,
  faHandHoldingDollar as fasHandHoldingDollar,
  faMemoPad as fasMemoPad,
  faQuestion as fasQuestion,
  faShieldCheck as fasShieldCheck,
  faShieldHalved as fasShieldHalved,
  faSpinnerScale as fasSpinnerScale,
  faTrash as fasTrash,
} from "@fortawesome/pro-solid-svg-icons";

// fat
import {
  faFolderPlus as fatFolderPlus,
  faMessageArrowUpRight as fatMessageArrowUpRight,
  faSquare1 as fatSquare1,
  faSquare2 as fatSquare2,
  faSquare3 as fatSquare3,
  faSquareArrowDown as fatSquareArrowDown,
  faSquareArrowUp as fatSquareArrowUp,
} from "@fortawesome/pro-thin-svg-icons";

// fass
import {
  faCheck as fassCheck,
  faDumbbell as fassDumbbell,
  faHockeyStickPuck as fassHockeyStickPuck,
  faHouseHeart as fassHouseHeart,
  faLightbulbOn as fassLightbulbOn,
  faMemoPad as fassMemoPad,
  faMobile as fassMobile,
  faPenNib as fassPenNib,
  faStamp as fassStamp,
} from "@fortawesome/sharp-solid-svg-icons";

import { library, config } from "@fortawesome/fontawesome-svg-core"
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"

// This is important, we are going to let Nuxt worry about the CSS
config.autoAddCss = false

library.add(
  fabCanadianMapleLeaf,
  fabCcVisa,
  fabFacebookF,
  fabLinkedin,
  fabXTwitter,
  fadBroomWide,
  fadCircleInfo,
  fadFileCirclePlus,
  fadLoader,
  fadTriangleExclamation,
  falArrowDown,
  falArrowDownLong,
  falArrowRight,
  falArrowUpFromBracket,
  falArrowsRotate,
  falBriefcase,
  falChampagneGlasses,
  falCheck,
  falCircleSmall,
  falClockRotateLeft,
  falClone,
  falComments,
  falComputer,
  falCreditCard,
  falEnvelopeOpenText,
  falEye,
  falEyeSlash,
  falFileAlt,
  falFileCirclePlus,
  falFileLines,
  falFilePdf,
  falFiles,
  falFloppyDisk,
  falGear,
  falHeartPulse,
  falKeyboard,
  falLanguage,
  falMessageBot,
  falMoneyFromBracket,
  falNfcSlash,
  falPeopleRoof,
  falPlus,
  falPowerOff,
  falQuestionCircle,
  falSave,
  falSearch,
  falShieldCheck,
  falSquareEllipsisVertical,
  falTriangleExclamation,
  falUser,
  farArrowLeftLong,
  farArrowRightToBracket,
  farDash,
  farEnvelopeCircleCheck,
  farPrint,
  farTrash,
  fasArrowDown,
  fasArrowRight,
  fasBracketsCurly,
  fasCheck,
  fasCircleExclamation,
  fasCircleQuestion,
  fasGear,
  fasGrid2,
  fasHandHoldingDollar,
  fasMemoPad,
  fasQuestion,
  fasShieldCheck,
  fasShieldHalved,
  fasSpinnerScale,
  fasTrash,
  fatFolderPlus,
  fatMessageArrowUpRight,
  fatSquare1,
  fatSquare2,
  fatSquare3,
  fatSquareArrowDown,
  fatSquareArrowUp,
  fassCheck,
  fassDumbbell,
  fassHockeyStickPuck,
  fassHouseHeart,
  fassLightbulbOn,
  fassMemoPad,
  fassMobile,
  fassPenNib,
  fassStamp
);

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.component('fa', FontAwesomeIcon, {})
})
