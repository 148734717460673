import { useWindowSize } from '@vueuse/core'
import { computed } from 'vue'

export default defineNuxtPlugin(() => {
  const { width } = useWindowSize()
  const breakpoint = { 'sm': 640, 'md': 768, 'lg': 1024, 'xl': 1280, '2xl': 1536 }
  const isMobile = computed(() => {
    return width.value < breakpoint.md ? true : false
  })

  return {
    provide: {
      isMobile
    }
  }
})
