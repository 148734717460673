import validate from "/var/deploy-agents/tgi/_work/1/s/node_modules/nuxt/dist/pages/runtime/validate.js";
import manifest_45route_45rule from "/var/deploy-agents/tgi/_work/1/s/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  manifest_45route_45rule
]
export const namedMiddleware = {
  restrict: () => import("/var/deploy-agents/tgi/_work/1/s/middleware/restrict.js"),
  session: () => import("/var/deploy-agents/tgi/_work/1/s/middleware/session.js"),
  auth: () => import("/var/deploy-agents/tgi/_work/1/s/node_modules/@sidebase/nuxt-auth/dist/runtime/middleware/auth.mjs")
}